

























import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "EmptyPage"
})
export default class EmptyPage extends Vue {
  @Prop({ default: "350px" }) imageWidth!: string;
  image: string = require("@/assets/images/clip-e-mail-marketing-campaign.png");
}
